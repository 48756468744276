import React, { Component, Fragment } from 'react'
import { addUser, editUser } from 'redux/actions/userAccessActions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
// import { toast } from "react-toastify";
import { Input, MyToast } from 'components/Common'
import { PrimaryButton, PrimaryOutlineButton } from 'styledComponent'
import {
  analyticsEnabledVendors,
  forKantiSweets,
} from '../../conditionalComponent'
import {
  modules,
  others,
  productAnalytics,
  consumerAnalytics,
  consumerProfile,
  reports,
  permissions,
  autoEngage,
  bills,
} from './constants'
import checkfeaturesAlloted from 'utils/checkFeatureAlloted'
class UserAccessHelper extends Component {
  static contextType = MyToast

  constructor(props) {
    super(props)
    this.state = {
      _id: '',
      email: '',
      password: '',
      confirmPassword: '',
      phoneNumber: '',
      formType: 'Add',
      accessModules: [],
      UserAccess: {
        access: {
          read: [],
          write: [],
        },
      },
      validationErrors: {},
      formAnimateClass: 'animated slideInRight',
    }

    this.CheckAccess = this.CheckAccess.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.presentAccess = this.presentAccess.bind(this)
    this.isEmailReadOnly = this.isEmailReadOnly.bind(this)
    this.postSuccess = this.postSuccess.bind(this)
    this.clearForm = this.clearForm.bind(this)
    this.animationEnd = this.animationEnd.bind(this)
  }

  presentAccess(current, permission1) {
    if (permission1 === 'read') {
      return this.state.UserAccess.access.read.includes(current)
    }
    if (permission1 === 'write') {
      return this.state.UserAccess.access.write.includes(current)
    }
  }

  clearForm(e) {
    if (e) e.preventDefault()
    this.setState({
      _id: '',
      email: '',
      phoneNumber: '',
      password: '',
      confirmPassword: '',
      formType: 'Add',
      UserAccess: {
        access: {
          read: [],
          write: [],
        },
      },
      formAnimateClass: 'animated slideInRight',
      validationErrors: {},
    })
    this.props.onUserUpdate()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.user._id !== '' &&
      this.props.user._id !== prevProps.user._id
    ) {
      this.setState({
        formAnimateClass: '',
      })
      const permissions =
        this.props.user.stores[this.props.storeIds[0]].permissions
      let read = [],
        write = Object.keys(permissions).filter(
          (moduleKey) => permissions[moduleKey].toLowerCase() === 'w'
        )

      write.length >= 8 && write.push('all')
      this.setState({
        _id: this.props.user._id,
        email: this.props.user.email,
        phoneNumber: this.props.user.phoneNumber || '',
        formType: 'Modify',
        UserAccess: {
          access: {
            read,
            write,
          },
        },
        formAnimateClass: 'animated bounceInDown',
      })
    }
  }

  CheckAccess(name, permission) {
    if (name === 'all') {
      if (permission === 'read') {
        let accessArray
        if (
          this.state.UserAccess.access.read.length !==
          this.state.accessModules.length
        ) {
          accessArray = this.state.accessModules.map((acc) => acc?.id)
        } else {
          accessArray = []
        }

        this.setState((prevState) => ({
          UserAccess: {
            access: {
              read: accessArray,
              write: [...prevState.UserAccess.access.write],
            },
          },
        }))
      }
      if (permission === 'write') {
        let accessArray
        if (
          this.state.UserAccess.access.write.length !==
          this.state.accessModules.length
        ) {
          accessArray = this.state.accessModules.map((acc) => acc?.id)
        } else {
          accessArray = []
        }

        this.setState((prevState) => ({
          UserAccess: {
            access: {
              write: accessArray,
              read: accessArray,
            },
          },
        }))
      }
    } else {
      if (!this.presentAccess(name, permission)) {
        if (permission === 'read') {
          this.setState((prevState) => ({
            UserAccess: {
              access: {
                read: [...prevState.UserAccess.access.read, name],
                write: [...prevState.UserAccess.access.write],
              },
            },
          }))
        } else {
          this.setState((prevState) => ({
            UserAccess: {
              access: {
                write: [...prevState.UserAccess.access.write, name],
                read:
                  this.state.UserAccess.access.read.indexOf(name) > -1
                    ? [...prevState.UserAccess.access.read]
                    : [...prevState.UserAccess.access.read, name],
              },
            },
          }))
        }
      } else {
        if (permission === 'read') {
          let readArray = this.state.UserAccess.access.read
          let writeArray = this.state.UserAccess.access.write

          let indexRead = readArray.indexOf(name)
          if (indexRead > -1) {
            readArray.splice(indexRead, 1)
          }
          let indexWrite = writeArray.indexOf(name)
          if (indexWrite > -1) {
            writeArray.splice(indexWrite, 1)
          }

          this.setState((prevState) => ({
            UserAccess: {
              access: {
                read: readArray,
                write: writeArray,
              },
            },
          }))
        } else {
          let writeArray = this.state.UserAccess.access.write
          let indexWrite = writeArray.indexOf(name)
          if (indexWrite > -1) {
            writeArray.splice(indexWrite, 1)
          }
          this.setState((prevState) => ({
            UserAccess: {
              access: {
                write: writeArray,
                read: [...prevState.UserAccess.access.read],
              },
            },
          }))
        }
      }
    }
  }

  onChange(e) {
    if (e.target.name === 'phoneNumber' && e.target.value.length > 10) return

    this.setState({ [e.target.name]: e.target.value })
  }

  componentDidMount() {
    this.props.onRef(this)
    const acModulePermissions = permissions.filter((item) =>
      checkfeaturesAlloted(item, this.props.featuresAllotted)
    )
    const acModule = acModulePermissions.map((item) => {
      if (item instanceof Array && autoEngage.some((r) => item.includes(r)))
        return modules.autoEngage
      if (item instanceof Array && bills.some((r) => item.includes(r)))
        return modules.bills
      return modules[item]
    })

    // FIXME: Cleanup: Remove the constants for feature check

    /* acModule.push(productAnalytics)

    acModule.push(consumerProfile)
    
    acModule.push(reports)
    
    acModule.push(...others) */

    this.setState({
      accessModules: acModule,
    })
  }

  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  onSubmit(e) {
    e.preventDefault()
    const email = this.state.email.toString().toLowerCase().trim()
    const passwordMin8Max50OneLetterRegex = /^(?=.*[a-zA-Z])(?=.*\d).{8,50}$/
    this.setState({
      validationErrors: {},
    })
    if (
      !passwordMin8Max50OneLetterRegex.test(this.state.password) &&
      !this.state.password <= 50 &&
      this.state._id === ''
    ) {
      this.setState({
        validationErrors: {
          password:
            'Password must be between 8 and 50 characters and must include atleast one letter and number',
        },
      })
      return
    }
    if (
      this.state.password !== this.state.confirmPassword &&
      this.state._id === ''
    ) {
      this.setState({
        validationErrors: {
          confirmPassword: 'Passwords do not match.',
        },
      })
      return
    }
    let storeUser = {
      email,
      phoneNumber: this.state.phoneNumber || '',
      permissions: this.state.UserAccess.access,
      storeIds: this.props.storeIds,
      selectedVendorId: this.props.selectedVendorId,
    }
    if (this.state._id === '') {
      storeUser = {
        ...storeUser,
        password: this.state.password,
        confirm_password: this.state.confirmPassword,
      }
      this.props
        .addUser(storeUser)
        .then(this.postSuccess)
        .then((message) => {
          // toast.success(message)
          this.context.toast(message, 'success')
        })
        .catch((message) => {
          // toast.error(message)
          this.context.toast(message, 'error')
        })
    } else {
      this.props
        .editUser(storeUser)
        .then(this.postSuccess)
        .then((message) => {
          // toast.success(message)
          this.context.toast(message, 'success')
        })
        .catch((message) => {
          // toast.error(message)
          this.context.toast(message, 'error')
        })
    }
  }

  postSuccess(e) {
    if (e.code) {
      this.clearForm()
      this.props.onUserUpdate()
      return Promise.resolve(e.message)
    } else {
      return Promise.reject((e.payload && e.payload.message) || 'Unknown Error')
    }
  }

  isEmailReadOnly() {
    return this.state._id !== ''
  }

  animationEnd() {
    this.setState({
      formAnimateClass: '',
    })
  }

  render() {
    return (
      <div className="contentPart1">
        <form onSubmit={this.onSubmit}>
          <div
            className={
              'addUser shadow p-3 bg-white ' + this.state.formAnimateClass
            }
            onAnimationEnd={this.animationEnd}
          >
            <div className="heading">{this.state.formType} User</div>
            <div className="addUserForm">
              <div className="addUserInput">
                <Input
                  label="Email Address"
                  containerMargin={'0'}
                  isEmail
                  width="100%"
                  name="email"
                  disabled={this.isEmailReadOnly()}
                  placeholder="Enter Email Address"
                  onChange={this.onChange}
                  variant={'input'}
                  value={this.state.email}
                  required
                />
              </div>
              <div className="addUserInput">
                <Input
                  type="number"
                  width="100%"
                  containerMargin={'0'}
                  name="phoneNumber"
                  label={'Phone Number'}
                  placeholder="Enter Phone Number"
                  variant={'input'}
                  onChange={this.onChange}
                  value={this.state.phoneNumber}
                />
              </div>
              {this.state._id === '' ? (
                <Fragment>
                  <div className="addUserInput">
                    <Input
                      width="100%"
                      label="Password"
                      variant="input"
                      type="password"
                      name="password"
                      containerMargin={'0'}
                      placeholder="Enter Password"
                      onChange={this.onChange}
                      value={this.state.password}
                      validationError={this.state.validationErrors?.password}
                      required
                    />
                  </div>
                  <div className="addUserInput">
                    <Input
                      label="Confirm Password"
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      onChange={this.onChange}
                      value={this.state.confirmPassword}
                      required
                      containerMargin={'0'}
                      variant="input"
                      width="100%"
                      validationError={
                        this.state.validationErrors?.confirmPassword
                      }
                    />
                  </div>
                </Fragment>
              ) : null}
            </div>
            <div className="storePermissionTable">
              <table>
                <thead>
                  <tr>
                    <th className="accessName" />
                    <th className="accessAction" />
                  </tr>
                </thead>
                <tbody>
                  {!!this.state.accessModules &&
                    this.state.accessModules.map((mod, i) => {
                      if (!mod?.name) return null

                      return (
                        <tr key={i}>
                          <td className={`accessName colorGray`}>
                            {mod?.name || ''}
                          </td>
                          <td className="accessBox">
                            <div
                              className={
                                this.presentAccess(mod?.id || '', 'write')
                                  ? 'accessBoxChecked'
                                  : 'accessBoxCheck'
                              }
                              onClick={() =>
                                this.CheckAccess(mod?.id || '', 'write')
                              }
                            />
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
            <div className="buttonContainer">
              <PrimaryButton>Submit</PrimaryButton>
              <PrimaryOutlineButton onClick={this.clearForm}>
                Cancel
              </PrimaryOutlineButton>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  userEmail: state.auth.user.email,
  vendorIds: state.vendorIds.vendorIds.map((x) => x.value),
  selectedVendorId: state.vendorIds.selectedVendor.value,
  featuresAllotted: state.vendorIds.selectedVendor.featuresAlloted,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ addUser, editUser }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAccessHelper)
