import React, { useEffect, useRef } from 'react'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import { Skeleton } from 'components/Common'
import './securityTable.css'
import { Button, PrimaryOutlineButton } from '../../../../styledComponent'
import { ViewIcon } from 'assets/actions'
import { FormattedNumber } from 'react-intl'
import { useSelector } from 'react-redux'
import { useOnScreen } from 'hooks'
import moment from 'moment'

const SecurityTable = (props) => {
  const { isLoading, hasMore, fetchNextPage } = props
  const currencyCode = useSelector((state) => state.auth.user.currencyCode)
  const { measureRef: lastElementRef, observer, isIntersecting } = useOnScreen()
  useEffect(() => {
    if (isIntersecting && hasMore) {
      fetchNextPage(props.rows.length - 1)
      observer.disconnect()
    }
  }, [isIntersecting])
  return (
    <>
      <Table style={{ fontSize: '1.5rem' }}>
        <Thead>
          <Tr>
            <Th key={1}>Name</Th>
            <Th key={2}>Phone</Th>
            <Th key={3}>Email</Th>
            <Th key={4}>QTY</Th>
            <Th key={5}>Amount</Th>
            <Th key={6}>Time</Th>
            <Th key={7} style={{ textAlign: 'center' }}>
              View Bill
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.rows?.map((row, i) => {
            return (
              <>
                <Tr key={i} style={{ lineHeight: '2' }}>
                  <Td key={i + '1'}>
                    {row?.parsedData?.customerData?.firstName}
                  </Td>
                  <Td key={i + '2'}>
                    {row?.user !== 'N.A' && !!row?.user
                      ? row?.user.substring(5)
                      : '-'}
                  </Td>
                  <Td key={i + '3'}>
                    {row?.email ? row?.email.replace(/@[\w.-]+/g, '@****') : ''}
                  </Td>
                  <Td key={i + '4'}>
                    {row?.parsedData?.billAmountData?.totalQty}
                  </Td>
                  <Td key={i + '5'}>
                    {row?.amount ? (
                      <FormattedNumber
                        style="currency"
                        currency={
                          row?.parsedData?.billAmountData?.saleCurrency ||
                          row?.other?.currencyCode ||
                          currencyCode
                        }
                        value={row.amount}
                        minimumFractionDigits={0}
                        maximumFractionDigits={5}
                      />
                    ) : (
                      '-'
                    )}
                  </Td>
                  <Td key={i + '6'}>
                    {moment(row?.createdDate).format('HH:mm A')}
                  </Td>
                  <Td key={i + '7'} style={{ textAlign: 'center' }}>
                    <PrimaryOutlineButton
                      onClick={() => props.onBillViewClick(row)}
                    >
                      <ViewIcon />
                    </PrimaryOutlineButton>
                  </Td>
                </Tr>
                {props.rows.length - 1 === i ? (
                  <div ref={lastElementRef} />
                ) : null}
              </>
            )
          })}
        </Tbody>
      </Table>
      {isLoading ? <Skeleton height={70} count={10} /> : null}
    </>
  )
}

export default SecurityTable
